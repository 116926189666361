import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';

export const SafeFormattedMessage = (props) => {
  const intl = useIntl();
  if (intl.messages[props?.id]) {
    return <FormattedMessage {...props} />;
  }
  return props.id ?? props.defaultMessage ?? null;
};

export const useTranslationExistenceChecker = () => {
  const intl = useIntl();
  const doesTranslationExist = ({ id }) => {
    const concatenatedWords = id
      .split(' ')
      .map(word => isNaN(word) ? capitalizeFirstLetter(word) : word)
      .join('');

    return !!intl.messages[concatenatedWords] ? intl.formatMessage({ id: concatenatedWords }) : id;
  };

  return {
    doesTranslationExist,
  };
};

/**Helper hook to safely handle translation of dynamically generated keys which may(not) exist translations.*/
export const useSafeIntl = () => {
  const intl = useIntl();

  return {
    formatMessageSafely: ({ id }) => {
      if (intl.messages[id]) {
        return intl.formatMessage({ id });
      }

      return id;
    },
  };
};
