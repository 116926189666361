import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { IModalComponentProps } from 'components/Modal';
import TicketingForm from '../TicketingForm';

import {
  useMobileContext,
  useThemeContext,
} from 'AppProvider/ConfigProviderSettings';
import { TICKET_TYPES } from '../types';

interface ITicketingModalContext {
  currentTicketType?: TICKET_TYPES;
  ticketingModalProps?: any;
  setCurrentTicketType?: any;
  openFormTicketingModal?: (scope?: TICKET_TYPES) => void;
  closeTicketingModal?: () => void;
}

const useTicketingModalContext = (): ITicketingModalContext => {
  const [currentTicketType, setCurrentTicketType] =
    React.useState<TICKET_TYPES>(null);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const { theme } = useThemeContext();
  const { isMobile, isTabletDevice } = useMobileContext();

  /** Opens the Modal where the create/view/update event form is rendered
   * @param {'incident' | 'service'} scope - the type of the form
   * @param {EventResponseDto} eventObject - the Event object that contains the details needed to view/edit event
   */
  const openFormTicketingModal = (scope?: TICKET_TYPES) => {
    setCurrentTicketType(scope);
    setModalOpen(true);
  };

  const closeTicketingModal = () => {
    setModalOpen(false);
  };

  const ticketingModalProps: IModalComponentProps = {
    isOpen: modalOpen,
    setIsOpen: setModalOpen,
    isMaskClosable: false,
    modalKey: 'ticketingEventFormModal',
    formKeyId: 'ticketingEventForm',
    className: 'mobile-modal',

    submitBtnText: (
      <FormattedMessage
        id={currentTicketType === 'incident' ? 'Ticketing_Send' : 'request'}
      />
    ),

    cancelBtnText: <FormattedMessage id={'cancel'} />,

    title: (
      <div
        style={{ width: '100%', textAlign: 'center' }}
        className={`modal-title ${theme}`}
      >
        <FormattedMessage
          id={
            currentTicketType === 'incident'
              ? 'Ticketing_Report_Incident'
              : 'Ticketing_Request_Service'
          }
        />
      </div>
    ),

    onCancelPressed: () => closeTicketingModal(),

    children: <TicketingForm ticketType={currentTicketType as any} />,
  };

  return {
    currentTicketType,
    ticketingModalProps,
    setCurrentTicketType,
    openFormTicketingModal,
    closeTicketingModal,
  };
};

const TicketingModalContext = React.createContext(
  {} as ReturnType<typeof useTicketingModalContext>
);

export const useTicketingModal = () => {
  return React.useContext(TicketingModalContext);
};

export const TicketingModalContextProvider = ({ children }) => {
  return (
    <TicketingModalContext.Provider value={useTicketingModalContext()}>
      {children}
    </TicketingModalContext.Provider>
  );
};
