import { useAuth } from 'services/providers/AuthProvider';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { Checkbox, DatePicker, Form, Input, Select, TimePicker } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { convertToLocalDate, convertToUTC } from 'components/DateParser';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ro';
import { memo, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import { EmployeeServices, EventService } from 'services';
import { IEmployee } from 'services/providers/EmployeeProvider/types';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import { adminRoles, getCurrentUserRole } from 'utils/userManagement';
import { useCalendarModal } from '../CalendarModalContext';
import { isAllDayEvent, isEventRecurrent } from '../CalendarPage/utils';
import { EventRecurrence, EventTimeFormat, EventTypes } from '../types';
import { EmployeesAvatarDropdown } from './EmployeesAvatarDropdown';
import './calendarEventForm.less';
import {
  ICreateCalendarEventForm,
  IUpdateCalendarEventForm,
  IViewCalendarEventForm
} from './types';
import { EventTypesManipulatePerRole } from './utils';
import ComponentWithStatus from '../../../components/ComponentWithStatus';
import { EditEventPLaceHolder } from '../../../components/Placeholders/Form';
import { useSafeIntl } from '../../../components/UIComponents/SafeFormattedMessage';
import { SelectDropdown } from '../../../components/Dropdown';

const { Option } = Select;
const DATE_PICKER_FORMAT = 'DD/MM/YYYY';
export const CalendarEventForm: React.FC<
  IUpdateCalendarEventForm | IViewCalendarEventForm | ICreateCalendarEventForm
> = memo(({ formScope = 'create', eventObject = null, status, type }) => {
  const [form] = Form.useForm();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { closeCalendarModal, setUpdateRecurringSeries } = useCalendarModal();
  const generalRootState = useSelector((state: RootState) => state);
  const user = useSelector((state: RootState) => state.user?.loggedUser);
  const isUserAdmin = adminRoles.includes(user?.role);
  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedMemberIds, setSelectedMemberIds] = useState<Array<any>>([]);
  const [selectedEventType, setSelectedEventType] = useState<EventTypes>(
    !isUserAdmin ? EventTypes.PersonalEvent : null
  );
  const [selectedEventTimeFormat, setSelectedEventTimeFormat] = useState(
    EventTimeFormat.AllDay
  );
  const [isReccurenceSelected, setIsReccurenceSelected] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      ['create', 'edit'].includes(formScope) &&
      selectedEventType === 'PersonalEvent'
    ) {
      EmployeeServices.getAllEmployeesList('true').then(res => {
        setAllEmployees(res.data);
        form.setFieldValue(
          'members',
          res.data
            ?.map(employee =>
              selectedMemberIds?.includes(employee.employeeId)
                ? employee.employeeId
                : undefined
            )
            .filter(i => i !== undefined)
        );
      });
    }
  }, [formScope, selectedEventType]);

  //initialize event data in form fields
  useEffect(() => {
    if (eventObject) {
      setSelectedEventType(eventObject?.eventType);
      setSelectedEventTimeFormat(eventObject?.timeFormat);
      setSelectedTeamId(eventObject?.teamId);
      setSelectedMemberIds(
        eventObject?.members
          ?.map(member => member?.employeeId as string)
          .filter(i => i !== undefined)
      );

      const {
        endDate,
        eventId,
        eventName,
        eventType,
        timeOffType,
        recurrence,
        recurrenceEndDate,
        startDate,
        teamName,
        timeFormat,
        details,
        location
      } = eventObject;

      const localStartDate = convertToLocalDate(startDate);
      const localEndDate = convertToLocalDate(endDate);
      const localReccurenceEndDate = convertToLocalDate(recurrenceEndDate);

      form.setFieldsValue({
        eventId,
        eventType,
        timeOffType,
        eventName,
        date: isAllDayEvent(eventObject)
          ? startDate && convertToUTC(localStartDate)
          : startDate && localStartDate,
        time: startDate && endDate && [localStartDate, localEndDate],
        location,
        recurrence,
        timeFormat,
        recurrenceEndDate: recurrenceEndDate && localReccurenceEndDate,
        details,
        teamName
      });

      setIsReccurenceSelected(isEventRecurrent(eventObject));
    } else {
      form.setFieldsValue({
        eventType: !isUserAdmin ? 'PersonalEvent' : undefined,
        recurrence: EventRecurrence.DoesNotRepeat,
        createdBy: `${user?.firstName} ${user?.lastName}, ${intl.formatMessage({
          id: user?.position
        })}`
      });
    }
  }, [eventObject?.eventId]);

  const UserTeams = {};
  generalRootState?.teams?.teams?.map(team => {
    if (team.leadEmployeeId === user?.employeeId || isUserAdmin) {
      UserTeams[team.teamId] = team;
    }
  });

  const { user: authUser } = useAuth();
  const currentUserRole = getCurrentUserRole(authUser);

  const EventTypesPerRoleAndActionArray =
    EventTypesManipulatePerRole[currentUserRole][
      formScope === 'view' ? 'View' : 'Mutate'
    ];

  const isTeamEventSelected = [EventTypes.TeamEvent].includes(
    selectedEventType
  );

  const isEventFormatWithHoursSelected =
    selectedEventTimeFormat === EventTimeFormat.TimeRange;

  const isEditOrCreateFormScope = ['edit', 'create'].includes(formScope);

  const isSelectedEventTypePersonal = [EventTypes.PersonalEvent].includes(
    selectedEventType
  );

  const safeIntl = useSafeIntl();

  useEffect(() => {
    if (formScope === 'create') {
      const timeFormat =
        selectedEventType === 'Holiday'
          ? safeIntl.formatMessageSafely({ id: EventTimeFormat.AllDay })
          : null;
      form.setFieldValue('timeFormat', timeFormat);
    }
  }, [selectedEventType]);

  return (
    <ComponentWithStatus
      status={status}
      Placeholder={
        <EditEventPLaceHolder count={6} gutter={6} heightProps="300px" />
      }
    >
      <Form
        form={form}
        id="calendarEventForm"
        layout="horizontal"
        size="middle"
        labelAlign="left"
        className={formScope === 'create' && 'new-event-form'}
        disabled={formScope === 'view'}
        labelWrap
        onFinish={formData => {
          const startDate = isEventFormatWithHoursSelected
            ? dayjs(
                formData.date
                  .set('hour', formData.time[0].get('hour'))
                  .set('minute', formData.time[0].get('minute'))
              )
            : formData.date.format('YYYY-MM-DDT00:00:00[Z]');

          const endDate = isEventFormatWithHoursSelected
            ? dayjs(
                formData.date
                  .set('hour', formData.time[1].get('hour'))
                  .set('minute', formData.time[1].get('minute'))
              )
            : formData.date.format('YYYY-MM-DDT00:00:01[Z]');

          const onFormFinishActions = {
            edit: EventService.updateEvent,
            create: EventService.addEvent
          };
          if (selectedEventType === 'Holiday')
            formData.timeFormat = EventTimeFormat.AllDay;

          const submitDataObj = (() => {
            const data = {
              ...formData,
              memberIds: selectedMemberIds,
              startDate: startDate,
              endDate: endDate,
              teamId: selectedTeamId,
              recurrentEventStartDate: startDate
            };

            if (formScope === 'create') {
              return data;
            }

            if (formScope === 'edit') {
              return { ...data, eventId: eventObject?.eventId };
            }
          })();

          const successMessage = (() => {
            if (formScope === 'edit') {
              return intl.formatMessage({ id: 'eventUpdatedSuccessfully' });
            }

            if (formScope === 'create') {
              return intl.formatMessage({
                id: 'eventCreatedSuccessfully'
              });
            }
          })();

          onFormFinishWithPassedValidation(
            onFormFinishActions,
            formScope,
            submitDataObj,
            {
              dispatch,
              toggleModal: () => closeCalendarModal()
            },
            successMessage
          );
        }}
      >
        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="eventCategory" />}
          name="eventType"
          isReadOnly={
            formScope === 'view'
              ? `col-form-item-component-view ${theme}`
              : `col-form-item-component ${theme}`
          }
          defaultValue={!isUserAdmin ? 'PersonalEvent' : undefined}
          rules={[
            {
              required: isEditOrCreateFormScope,
              message: (
                <FormattedMessage id="CalendarCreateNewEventValidation_event_category" />
              )
            }
          ]}
        >
          <SelectDropdown
            placement_bottom={true}
            onChange={value => setSelectedEventType(EventTypes[value])}
            defaultValue={!isUserAdmin ? 'PersonalEvent' : undefined}
            disabled={formScope !== 'create'}
          >
            {EventTypesPerRoleAndActionArray?.map(eventCategory => (
              <Option key={eventCategory} value={eventCategory}>
                <FormattedMessage id={eventCategory} />
              </Option>
            ))}
          </SelectDropdown>
        </ColFormItemComponent>

        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="selectTeam" />}
          name="teamName"
          hideWhen={() => {
            if (formScope === 'view') {
              return !form.getFieldValue('teamName');
            }

            if (formScope === 'create') {
              return !isTeamEventSelected;
            }

            return eventObject?.eventType !== 'TeamEvent';
          }}
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          rules={[
            {
              required: isEditOrCreateFormScope,
              message: (
                <FormattedMessage id="CalendarCreateNewEventValidation_event_team_category" />
              )
            }
          ]}
        >
          <SelectDropdown
            placement_bottom={true}
            onChange={(value, option) => {
              //there must be some TS issue on Antd, since option.key is an accessible property
              //@ts-ignore
              setSelectedTeamId(option.key);
            }}
            disabled={formScope !== 'create'}
          >
            {Object.values(UserTeams)?.map(({ teamId, name }) => (
              <Option key={teamId} value={name}>
                {name}
              </Option>
            ))}
          </SelectDropdown>
        </ColFormItemComponent>

        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="title" />}
          name="eventName"
          isReadOnly={
            formScope === 'view'
              ? `col-form-item-component-view ${theme}`
              : `col-form-item-component ${theme}`
          }
          hideWhen={() => formScope === 'view'}
          rules={[
            {
              required: isEditOrCreateFormScope,
              message: (
                <FormattedMessage id="CalendarCreateNewEventValidation_event_title" />
              )
            },
            {
              max: 100
            }
          ]}
        >
          <Input />
        </ColFormItemComponent>

        <ColFormItemComponent
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          span={24}
          label={<FormattedMessage id="date" />}
          hideWhen={() =>
            formScope === 'view' && form.getFieldValue('date') === null
          }
          name="date"
          rules={[
            {
              required: isEditOrCreateFormScope,
              message: (
                <FormattedMessage id="CalendarCreateNewEventValidation_event_date" />
              )
            }
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            popupClassName={theme}
            format={DATE_PICKER_FORMAT}
          />
        </ColFormItemComponent>
        <ColFormItemComponent
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          span={24}
          label={<FormattedMessage id="eventTimeFormat" />}
          name="timeFormat"
          hideWhen={() =>
            formScope === 'view' &&
            (!form.getFieldValue('timeFormat') ||
              form.getFieldValue('timeFormat') === 'None')
          }
          rules={[
            {
              required: isEditOrCreateFormScope,
              message: (
                <FormattedMessage id="CalendarCreateNewEventValidation_event_time" />
              )
            }
          ]}
        >
          {selectedEventType === 'Holiday' && formScope === 'create' ? (
            <Input disabled={true} />
          ) : (
            <SelectDropdown
              placement_bottom={true}
              onChange={value => setSelectedEventTimeFormat(value)}
              disabled={formScope !== 'create'}
            >
              {Object.values(EventTimeFormat).map((eventTimeFormat, index) => (
                <Option key={index} value={eventTimeFormat}>
                  {<FormattedMessage id={eventTimeFormat} />}
                </Option>
              ))}
            </SelectDropdown>
          )}
        </ColFormItemComponent>
        <>
          <ColFormItemComponent
            span={24}
            isReadOnly={
              formScope === 'view'
                ? `col-form-item-component-view ${theme}`
                : ``
            }
            label={<FormattedMessage id="selectHours" />}
            name="time"
            hideWhen={() => !isEventFormatWithHoursSelected}
            rules={[
              {
                required: isEditOrCreateFormScope,
                message: (
                  <FormattedMessage id="CalendarCreateNewEventValidation_event_hours" />
                )
              },
              {
                validator(_, value) {
                  const validationErrorMessage = intl.formatMessage({
                    id: 'ValidationTimeRangeEndTimeLessStartTime'
                  });

                  if (
                    value !== undefined &&
                    value[0] !== null &&
                    value[1] !== null &&
                    value[0].hour() >= value[1].hour() &&
                    value[0].minute() >= value[1].minute()
                  ) {
                    return Promise.reject(validationErrorMessage);
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}
          >
            <TimePicker.RangePicker
              popupClassName={`time-range-picker ${theme}`}
              style={{ width: '100%' }}
              format={'HH:mm'}
            />
          </ColFormItemComponent>
        </>

        <ColFormItemComponent
          hideWhen={() => {
            return (
              ![EventTypes.Holiday, EventTypes.TimeOffEvent].includes(
                selectedEventType
              ) ||
              formScope === 'view' ||
              formScope === 'edit' ||
              (formScope === 'create' && !form.getFieldValue('location'))
            );
          }}
          span={24}
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          label={<FormattedMessage id="location" />}
          name="location"
        >
          <Input />
        </ColFormItemComponent>
        <ColFormItemComponent
          hideWhen={() =>
            !isSelectedEventTypePersonal ||
            (formScope === 'view' && !form.getFieldValue('createdBy'))
          }
          span={24}
          label={<FormattedMessage id="owner" />}
          name="createdBy"
          initialValue={`${user?.firstName} ${
            user?.lastName
          }, ${intl.formatMessage({ id: user?.position })}`}
          isReadOnly="true"
        >
          <Input
            type="text"
            className={`link-input ownerInput-${theme}`}
            onClick={() => {
              closeCalendarModal();
              navigate(
                `/allUsersList/viewProfile?employeeId=${
                  formScope === 'create'
                    ? user?.employeeId
                    : eventObject?.employeeId
                }`
              );
            }}
          />
        </ColFormItemComponent>

        <ColFormItemComponent
          span={24}
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          label={<FormattedMessage id="recurrence" />}
          name="recurrence"
          hideWhen={() => {
            if (formScope === 'view' || formScope === 'edit') {
              return (
                [EventTypes.TimeOffEvent].includes(selectedEventType) ||
                !form.getFieldValue('recurrence')
              );
            }
          }}
          rules={[
            {
              required: isEditOrCreateFormScope,
              message: 'Please select reccurence'
            }
          ]}
        >
          <SelectDropdown
            placement_bottom={false}
            onChange={(value, option) => {
              setIsReccurenceSelected(value !== EventRecurrence.DoesNotRepeat);
            }}
          >
            {Object.values(EventRecurrence).map(eventReccurence => (
              <Option key={eventReccurence} value={eventReccurence}>
                <FormattedMessage id={eventReccurence} />
              </Option>
            ))}
            ))
          </SelectDropdown>
        </ColFormItemComponent>
        <ColFormItemComponent
          span={24}
          hideWhen={() => !isReccurenceSelected}
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          label={<FormattedMessage id="recurrenceEndDate" />}
          name="recurrenceEndDate"
          rules={[
            {
              required: isEditOrCreateFormScope,
              message: 'Please select the reccurence end date'
            }
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            popupClassName={theme}
            format={DATE_PICKER_FORMAT}
          />
        </ColFormItemComponent>
        <ColFormItemComponent
          span={24}
          hideWhen={() => !(formScope === 'edit' && isReccurenceSelected)}
          label={<FormattedMessage id="updateRecurrent" />}
          name="updateRecurringSeries"
          valuePropName="checked"
        >
          <Checkbox
            onChange={e => setUpdateRecurringSeries(e.target.checked)}
          />
        </ColFormItemComponent>
        <ColFormItemComponent
          hideWhen={() =>
            formScope === 'view' && !form.getFieldValue('details')
          }
          span={24}
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          label={<FormattedMessage id="details" />}
          name="details"
        >
          <TextArea maxLength={500} autoSize={{ minRows: 3, maxRows: 6 }} />
        </ColFormItemComponent>
        <>
          <ColFormItemComponent
            hideWhen={() =>
              !isSelectedEventTypePersonal || !isEditOrCreateFormScope
            }
            span={24}
            label={<FormattedMessage id="members" />}
            name="members"
            rules={[
              {
                required: false,
                message: intl.formatMessage({ id: 'MembersSelectRequired' })
              }
            ]}
          >
            <SelectDropdown
              mode={'multiple'}
              placement_bottom={false}
              maxTagCount={2}
              onChange={(value, option) => {
                setSelectedMemberIds(prev => option.map(({ key }) => key));
              }}
              filterOption={(input, option) => {
                return (option.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {allEmployees.map(employee => (
                <Option key={employee.employeeId} value={employee.employeeId}>
                  {employee.lastName + ' ' + employee.firstName}
                </Option>
              ))}
              ))
            </SelectDropdown>
          </ColFormItemComponent>
        </>

        <ColFormItemComponent
          hideWhen={() => formScope !== 'view' || !isSelectedEventTypePersonal}
          span={24}
          isReadOnly={
            formScope === 'view' ? `col-form-item-component-view ${theme}` : ``
          }
          label={<FormattedMessage id="members" />}
          name="memberIds"
        >
          <EmployeesAvatarDropdown data={eventObject?.members as IEmployee[]} />
        </ColFormItemComponent>
      </Form>
    </ComponentWithStatus>
  );
});

export default CalendarEventForm;
