import { useAuth } from 'services/providers/AuthProvider';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { IModalComponentProps } from 'components/Modal';
import ConfirmModalComponent, {
  IConfirmModalComponentProps
} from 'components/Modal/ConfirmModal';
import { useConfirmationModalContext } from 'components/Modal/ConfirmModal/ConfirmationModalProvider';
import { useModalContext } from 'components/Modal/ModalProvider';
import dayjs from 'dayjs';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { RootState } from 'redux/store';
import { EventService } from 'services';
import { getCurrentUserRole } from 'utils/userManagement';
import CalendarEventForm from '../CalendarEventForm';
import EventsList from '../CalendarPage/EventsList';
import {
  isCompanyOrHolidayEvent,
  isEventRecurrent,
  isPersonalEventOfCurrentUser
} from '../CalendarPage/utils';
import {
  EventDefinitions,
  EventResponseDto,
  EventTypes,
  EventUpdateDto,
  ICalendarEventsPerDayResponse
} from '../types';
import { useEffect, useState } from 'react';
import useApiRequestHook from '../../../hooks/useApiRequest.hook';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { FormFieldPlaceholder } from 'components/Placeholders';

const ButtonComponent = BaseButton(ButtonSimple);

const useCalendarModalContext = () => {
  const [currentModalScope, setCurrentModalScope] = React.useState<
    'view' | 'create' | 'edit' | 'mobileViewPerDay'
  >('create');
  const [viewableEventObject, setViewableEventObject] =
    React.useState<EventResponseDto>(null); //the event object that is passed via openCalendarModal method
  const [confirmModalProps, setConfirmModalProps] =
    React.useState<IConfirmModalComponentProps>({});
  const [eventsPerDayViewData, setEventsPerDayViewData] =
    React.useState<EventResponseDto[]>(null);
  const [updateRecurringSeries, setUpdateRecurringSeries] =
    React.useState<boolean>(false);
  const [isOpen, setIsOpne] = useState(false);
  const [eventObject, setEventObject] = useState<EventResponseDto>(null);
  const [eventStartDate, setEventStartDate] = useState(null);
  const selectedDayView =
    eventsPerDayViewData &&
    dayjs(eventsPerDayViewData[0]?.startDate)?.format('DD/MM/YYYY'); //derived date from eventsPerDayViewData
  const intl = useIntl();
  const userId = useSelector(
    (state: RootState) => state.user?.loggedUser?.employeeId
  );
  const confirmModal = useConfirmationModalContext();
  const modal = useModalContext();
  const { user } = useAuth();
  const { theme } = useThemeContext();
  const dispatch = useDispatch();

  const currentUserRole = getCurrentUserRole(user);

  const generalRootState = useSelector((state: RootState) => state);
  const TLUserTeamIds =
    generalRootState.teams.teams &&
    Object.keys(generalRootState.teams.teams).length
      ? generalRootState.teams.teams
          .map(({ leadEmployeeId, teamId }) =>
            leadEmployeeId === userId ? teamId : null
          )
          .filter(i => i)
      : [];

  const userHasEditAccess = React.useMemo(() => {
    const isTeamEventAndUserIsTL =
      viewableEventObject?.eventType === EventTypes.TeamEvent &&
      TLUserTeamIds.includes(viewableEventObject?.teamId);

    if (currentModalScope === 'view') {
      //all users can edit their personal events only
      if (isPersonalEventOfCurrentUser(viewableEventObject, userId))
        return true;

      switch (currentUserRole) {
        case 'Admin': {
          return (
            isCompanyOrHolidayEvent(viewableEventObject) ||
            isTeamEventAndUserIsTL
          );
        }
        case 'TL': {
          return isTeamEventAndUserIsTL;
        }
        default:
          return false;
      }
    }
  }, [currentModalScope, viewableEventObject?.eventId, currentUserRole]);

  /**Modal which prompts user to delete event */
  const openConfirmDeleteModal = (onOk: () => Promise<any>) =>
    setConfirmModalProps(() => {
      confirmModal.setIsConfirmModalOpen(true);
      setEventObject(null);
      return {
        title: <FormattedMessage id="deleteEvent" />,
        confirmContent: <FormattedMessage id="confirmDeleteEvent" />,
        okText: intl.formatMessage({ id: 'delete' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        setMessageSuccessfully: <FormattedMessage id="eventDeleteSuccess" />,
        onOkModalAction: () =>
          onOk().then(() => {
            dispatch(setMethodUpdate(true));
            closeCalendarModal();
            confirmModal.setIsConfirmModalOpen(false);
          })
      };
    });

  /** Modal which promps the user to update all reccuring events
   * @param {EventUpdateDto} data - The data object that will be used to update the event
   */
  const openConfirmUpdateReccuringSeriesModal = (onOk: () => Promise<any>) =>
    setConfirmModalProps(() => {
      confirmModal.setIsConfirmModalOpen(true);
      return {
        title: <FormattedMessage id="updateRecurringSeries" />,
        confirmContent: <></>,
        okText: 'Yes',
        cancelText: 'No',
        setMessageSuccessfully: (
          <FormattedMessage id="updateRecurringSeriesSuccess" />
        ),

        onOkModalAction: () =>
          onOk().then(() => {
            dispatch(setMethodUpdate(true));
            confirmModal.setIsConfirmModalOpen(false);
            closeCalendarModal();
          })
      };
    });

  /** Opens the Modal where the create/view/update event form is rendered
   * @param {'view' | 'create' | 'edit'} scope - the type of the form
   * @param {EventResponseDto} eventObject - the Event object that contains shortened details about event
   */
  let { data, status } = useApiRequestHook<any>(
    EventService.getEvent,
    {
      eventId: eventObject?.eventId,
      recurrentEventStartDate: isEventRecurrent(eventObject)
        ? eventStartDate
        : undefined
    },
    [],
    [],
    null,
    [eventObject],
    eventObject === null ||
      !eventObject?.isExtendedViewAllowed ||
      eventObject?.eventName?.split('_').includes('BirthDate')
  );

  useEffect(() => {
    if (eventObject?.eventId || currentModalScope === 'create') {
      if (isOpen === true) {
        ['view', 'edit'].includes(currentModalScope) &&
          setViewableEventObject(data);
        (eventObject?.isExtendedViewAllowed ||
          currentModalScope === 'create') &&
          modal.setIsModalOpen(true);
      }
    } else {
      ['view', 'edit'].includes(currentModalScope) &&
        setViewableEventObject(eventObject);
      if (eventObject) modal.setIsModalOpen(true);
    }
  }, [data, isOpen]);
  const openFormCalendarModal = (
    scope: 'view' | 'create' | 'edit',
    eventObject?: EventResponseDto,
    eventStartDate?: dayjs.Dayjs
  ) => {
    setIsOpne(true);
    if (
      eventObject?.eventId ||
      eventObject?.eventName?.split('_').includes('BirthDate')
    ) {
      setCurrentModalScope(scope);
      setEventObject(eventObject);
      setEventStartDate(eventStartDate);
    }
  };
  const closeCalendarModal = () => {
    modal.setIsModalOpen(false);
    if (currentModalScope !== 'create') {
      setViewableEventObject(null);
      setEventsPerDayViewData(null);
    }

    setCurrentModalScope('create');
    setIsOpne(false);
  };

  /** Opens modal which render's the events per day for mobile devices */
  const openViewEventsPerDayModal = (
    selectedDate: dayjs.Dayjs,
    calendarDaysData: ICalendarEventsPerDayResponse
  ) => {
    modal.setIsModalOpen(true);
    setCurrentModalScope('mobileViewPerDay');
    setEventsPerDayViewData(calendarDaysData as any);
  };

  //Event calendar modal props
  const calendarModalProps: IModalComponentProps = {
    isMaskClosable: false,
    modalKey: 'calendarEventFormModal',
    formKeyId: 'calendarEventForm',
    className: 'mobile-modal',
    submitBtnText: (
      <FormattedMessage
        id={currentModalScope === 'create' ? 'create' : 'save'}
      />
    ),
    afterClose: () => closeCalendarModal(),
    cancelBtnText: (
      <FormattedMessage
        id={currentModalScope === 'view' ? 'close' : 'cancel'}
      />
    ),
    title: (
      <>
        {/* Confirm Modal Component is placed here just to be present in the DOM, not for any other particular reason */}
        <ConfirmModalComponent {...confirmModalProps} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <div
            className="text-ellipsis-fit-content"
            style={{ maxWidth: '350px', margin: '0 auto' }}
          >
            {viewableEventObject?.timeOffType ? (
              <>
                <FormattedMessage id={viewableEventObject?.timeOffType} />
                {', '}
                {viewableEventObject?.employeeName}
              </>
            ) : viewableEventObject?.eventName
                ?.split('_')
                .includes('BirthDate') ? (
              <FormattedMessage
                id="BirthDate"
                values={{
                  firstName: viewableEventObject?.eventName.split('_')[0],
                  lastName: viewableEventObject?.eventName.split('_')[1]
                }}
              />
            ) : status === 'success' ? (
              currentModalScope === 'create' ? viewableEventObject?.eventName : eventObject?.eventName
            ) : status === 'pending' ? (
              <ComponentWithStatus
                status={'pending'}
                Placeholder={<FormFieldPlaceholder heightProps="27px" />}
              >
                {currentModalScope === 'create' ? viewableEventObject?.eventName : eventObject?.eventName}
              </ComponentWithStatus>
            ) : (
              ''
            )}
            {currentModalScope === 'create' && (
              <FormattedMessage id={'newEvent'} />
            )}
            {currentModalScope === 'mobileViewPerDay' && selectedDayView}
          </div>

          <div
            style={{
              height: '4px',
              background:
                currentModalScope === 'create'
                  ? '#C4C4C4'
                  : EventDefinitions[eventObject?.eventType]
                      ?.badgeColor,
              borderRadius: '12px'
            }}
          ></div>
        </div>
      </>
    ),
    editButton:
      currentModalScope === 'view' && userHasEditAccess ? (
        <ButtonComponent
          className={`text-bold-normal btn-primary-custom ${theme}`}
          type="primary"
          htmlType="submit"
          id="submitButton"
          onClick={() =>
            currentModalScope === 'view' && setCurrentModalScope('edit')
          }
        >
          <FormattedMessage id="edit" />
        </ButtonComponent>
      ) : null,

    displayFooterSubmitButton: ['create', 'edit'].includes(currentModalScope),

    onCancelPressed: () => closeCalendarModal(),
    footerButtons: (
      <>
        {currentModalScope === 'edit' && (
          <ButtonComponent
            className={`text-bold-normal btn-default-custom  ${theme}`}
            key="closeModalButton"
            style={{ maxWidth: '100%' }}
            onClick={() => {
              openConfirmDeleteModal(() =>
                EventService.deleteEvent({
                  eventId: viewableEventObject?.eventId,
                  deleteRecurringSeries: updateRecurringSeries,
                  recurrentEventStartDate: isEventRecurrent(viewableEventObject)
                    ? viewableEventObject?.startDate
                    : undefined
                })
              );
            }}
          >
            <FormattedMessage id="delete" />
          </ButtonComponent>
        )}
      </>
    ),
    children: (
      <>
        {currentModalScope === 'mobileViewPerDay' ? (
          <EventsList data={eventsPerDayViewData} />
        ) : (
          <CalendarEventForm
            status={
              currentModalScope === 'create' ||
              eventObject?.eventName?.split('_').includes('BirthDate')
                ? 'success'
                : status
            }
            formScope={currentModalScope as any}
            type={eventObject?.eventType}
            eventObject={currentModalScope === 'create' ? viewableEventObject : eventObject}
          />
        )}
      </>
    )
  };

  return {
    calendarModalProps,
    openFormCalendarModal,
    closeCalendarModal,
    openConfirmDeleteModal,
    openConfirmUpdateReccuringSeries: openConfirmUpdateReccuringSeriesModal,
    openViewEventsPerDayModal,
    setUpdateRecurringSeries
  };
};

const CalendarModalContext = React.createContext(
  {} as ReturnType<typeof useCalendarModalContext>
);
/** Helper context which wraps the functionality of Calendar Event's modal for different scopes in a single context */
export const useCalendarModal = () => {
  return React.useContext(CalendarModalContext);
};

export const CalendarModalContextProvider = ({ children }) => {
  return (
    <CalendarModalContext.Provider value={useCalendarModalContext()}>
      {children}
    </CalendarModalContext.Provider>
  );
};
