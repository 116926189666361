import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppDispatch } from 'redux/store';

import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { TicketingService } from 'services';
import {
  ITicketCategory,
  ITicketSubcategory
} from 'services/providers/TicketingProvider/types';
import { notifyWithError } from 'utils/notificationsUtils';
import { onFormFinishWithPassedValidation } from 'utils/onSubmitForm';
import { useTicketingModal } from '../TicketingModalContext';
import { TICKET_TYPES, UrgencyValues } from '../types';

import { Form, Input, Row, Select, Upload, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import { SelectDropdown } from 'components/Dropdown';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { getFileUploadFormData } from 'utils/uploadFileRequestWithFormData';
import { DeleteIcon } from '../../../Icons';
import { useSafeIntl } from 'components/UIComponents/SafeFormattedMessage';
import { TimeOffDeleteIcon } from 'Icons/TimeOffActionIcons/TimeOffDeleteIcon';
import { TimeOffDownloadIcon } from 'Icons/TimeOffActionIcons/TimeOffDownloadIcon';
import { validateFileType } from 'components/UploadFile/utils';

const { Option } = Select;

interface IProps {
  ticketType: TICKET_TYPES;
}

const TicketingForm: React.FC<IProps> = React.memo(
  ({ ticketType = 'incident' }) => {
    const [form] = Form.useForm();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const { theme } = useThemeContext();
    const safeIntl = useSafeIntl();
    const intl = useIntl();
    const { currentTicketType, closeTicketingModal, setCurrentTicketType } =
      useTicketingModal();

    const ButtonCustom = BaseButton(ButtonSimple);

    const [selectedCategoryType, setSelectedCategoryType] =
      React.useState<any>(null);
    const [fileList, setFileList] = React.useState<any>([]);
    //upload file extension
    const acceptFilesExtension =
      'image/jpeg, image/jpg, image/png, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, video/mp4, video/x-msvideo, video/quicktime, video/x-ms-wmv, video/x-flv, video/avi, video/webm';

    // Get current URL only
    const url_scope = location.pathname.split('/')[1];

    // Fetch Ticket Categories
    const { data: ticketCategories } = useApiRequestHook<ITicketCategory[]>(
      TicketingService.getCategoriesByTicketType,
      ticketType === 'incident'
        ? { ticketType: 'Incident' }
        : { ticketType: 'Request' },
      [],
      {},
      null,
      [ticketType]
    );

    // Fetch Ticket Subcategories
    const { data: ticketSubcategories } = useApiRequestHook<
      ITicketSubcategory[]
    >(
      TicketingService.getAllCategorySubCategories,
      { categoryId: selectedCategoryType },
      [],
      {},
      null,
      [selectedCategoryType]
    );

    React.useEffect(() => {
      const defaultFields = {
        Priority:
          ticketType === 'incident' || currentTicketType === 'incident' ? 3 : 2,
        TicketType: ticketType
      };

      form.setFieldsValue(defaultFields);
    }, [currentTicketType]);

    const handleTicketType = (value: TICKET_TYPES) => {
      setCurrentTicketType(value);
      form.resetFields();
      setSelectedCategoryType(null);
    };

    return (
      <React.Fragment>
        <Form
          form={form}
          id="ticketingEventForm"
          layout="horizontal"
          size="middle"
          labelAlign="left"
          labelWrap
          colon={false}
          labelCol={{ span: 6 }}
          initialValues={{
            ticketType: null,
            categoryId: selectedCategoryType,
            Priority:
              ticketType === 'incident' || currentTicketType === 'incident'
                ? 3
                : 2,
            SubCategoryId: null,
            Details: null,
            Title: null
          }}
          onFinish={formData => {
            const parsedData = getFileUploadFormData(
              null,
              fileList.length > 0
                ? {
                  formDataFileName: 'Files',
                  uploadFiles: fileList
                }
                : null
            );

            parsedData.append(
              'Type',
              ticketType === 'service' ? 'Request' : 'Incident'
            );
            Object.entries(formData).map(([key, value]) =>
              parsedData.append(`${key}`, value as any)
            );

            parsedData.delete('TicketType');
            const onFormFinishActions = {
              create: TicketingService.addTicket
            };

            const successMessage = (() => {
              return <FormattedMessage id="Ticketing_Sent_Success" />;
            })();


            onFormFinishWithPassedValidation(
              onFormFinishActions,
              'create',
              parsedData,
              {
                dispatch,
                toggleModal: () => closeTicketingModal()
              },
              successMessage
            );
          }}
        >
          {/* TICKET CATEGORY */}

          {url_scope === 'ticketing' && (
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="Ticketing_Type" />}
              name="TicketType"
              className={`col-form-item-component ${theme} p-0`}
            >
              <SelectDropdown placement_bottom onChange={handleTicketType}>
                <Option key="incident" value="incident">
                  <FormattedMessage id="Ticketing_Incident" />
                </Option>
                <Option key="service" value="service">
                  <FormattedMessage id="Ticketing_Service" />
                </Option>
              </SelectDropdown>
            </ColFormItemComponent>
          )}

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Ticketing_Category" />}
            name="CategoryId"
            className={`col-form-item-component ${theme} p-0`}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="Ticketing_Category_Validation" />
              }
            ]}
          >
            <SelectDropdown
              placement_bottom
              onChange={value => setSelectedCategoryType(value)}
            >
              {ticketCategories?.map((ticketCategory: ITicketCategory) => (
                <Option
                  key={ticketCategory.categoryId}
                  value={ticketCategory.categoryId}
                >
                  {intl.formatMessage({ id: ticketCategory.key, defaultMessage: ticketCategory.name })}
                </Option>
              ))}
            </SelectDropdown>
          </ColFormItemComponent>

          {/* TICKET SUBCATEGORY  */}

          {selectedCategoryType && ticketSubcategories?.length !== 0 && (
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="Ticketing_Subcategory" />}
              name="SubCategoryId"
              className={`col-form-item-component ${theme} p-0`}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="Ticketing_Subcategory_Validation" />
                  )
                }
              ]}
            >
              <SelectDropdown placement_bottom>
                {ticketSubcategories?.map(
                  (ticketSubcategory: ITicketSubcategory) => (
                    <Option
                      key={ticketSubcategory.subCategoryId}
                      value={ticketSubcategory.subCategoryId}
                    >
                      {intl.formatMessage({ id: ticketSubcategory.key, defaultMessage: ticketSubcategory.name })}
                    </Option>
                  )
                )}
              </SelectDropdown>
            </ColFormItemComponent>
          )}

          {/* TICKET URGENCY */}

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Ticketing_Urgency" />}
            name="Priority"
            className={`col-form-item-component ${theme} p-0`}
          >
            <SelectDropdown allowClear={false} placement_bottom value={3}>
              {UrgencyValues.map((ticketUrgency: any) => (
                <Option key={ticketUrgency.value} value={ticketUrgency.value}>
                  {safeIntl.formatMessageSafely({ id: ticketUrgency.name })}
                </Option>
              ))}
            </SelectDropdown>
          </ColFormItemComponent>

          {/* TITLE FIELD */}

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Ticketing_Title" />}
            name="Title"
            className={`col-form-item-component ${theme} p-0`}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="Ticketing_Title_Validation" />
              }
            ]}
          >
            <Input type="text" maxLength={500} />
          </ColFormItemComponent>

          {/* DETAILS FIELD */}

          <ColFormItemComponent
            span={24}
            label={<FormattedMessage id="Ticketing_Details" />}
            name="Details"
            className={`col-form-item-component ${theme} p-0`}
            rules={[
              {
                required: true,
                min: 5,
                message: <FormattedMessage id="Ticketing_Details_Validation" />
              }
            ]}
          >
            <TextArea maxLength={500} autoSize={{ minRows: 3, maxRows: 3 }} />
          </ColFormItemComponent>

          {/* UPLOAD FILE */}

          <ColFormItemComponent
            span={24}
            name="Files"
            style={{
              display: 'flex',
              justifyContent: 'start',
              flexDirection: 'row-reverse',
              textAlign: 'center'
            }}
            wrapperCol={{ span: 24 }}
            className={`col-form-item-component ${theme} p-0`}
          >
            <Upload
              multiple={true}
              fileList={fileList}
              showUploadList={{
                showDownloadIcon: true,
                showRemoveIcon: true
              }}
              itemRender={(_, file) => {
                let binaryData = [];
                binaryData.push(file);

                return (
                  <div>
                    <div className={`label-attachement ${theme} `}>
                      <div className="attachemt_text">
                        <FormattedMessage id="attachment" />
                      </div>
                      <div className="attachemnt_container-btns">
                        <div className="btns-row">
                          <a
                            href={URL.createObjectURL(new Blob(binaryData))}
                            download={file.name}
                            rel="noopener noreferrer"
                            target={'_blank'}
                            className={`btn_download ${theme}`}
                          >
                            <TimeOffDownloadIcon />
                          </a>
                          <div className="separator"></div>
                          <ButtonCustom
                            className={`text-bold-normal btn-default-custom  ${theme}`}
                            type="button"
                            onClick={() => {
                              const index = fileList.indexOf(file);
                              const newFileList = fileList.slice();
                              newFileList.splice(index, 1);
                              setFileList(newFileList);
                            }}
                          >
                            <TimeOffDeleteIcon />
                          </ButtonCustom>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
              accept={acceptFilesExtension}
              maxCount={10}
              beforeUpload={uploadedFile => {
                const isAllowedType = validateFileType(
                  uploadedFile,
                  acceptFilesExtension
                );
                if (!isAllowedType) {
                  notifyWithError({
                    message: (
                      <FormattedMessage id="upload_file_type_not_allowed" />
                    ),
                    description: null
                  });
                  form.validateFields(['uploadFile']);
                  return false;
                }
                if (fileList && fileList.length > 10 - 1) {
                  notifyWithError({
                    message: (
                      <FormattedMessage
                        id="MaximumFilesToUploadErrorMessage"
                        values={{ maxFilesPerRequest: 10 }}
                      />
                    ),
                    description: null
                  });
                  form.validateFields(['uploadFile']);
                  return false;
                }

                if (uploadedFile.size && uploadedFile.size >= 10 * 1000000) {
                  notifyWithError({
                    message: (
                      <FormattedMessage id="Ticketing_Upload_Maximum_Exceeded" />
                    ),
                    description: null
                  });
                  form.validateFields(['uploadFile']);
                  return false;
                }

                setFileList([...fileList, uploadedFile]);
                return false;
              }}
            >
              <ButtonCustom
                className={`text-bold-normal btn-upload-custom  ${theme}`}
                style={{
                  padding: '4px 8px'
                }}
              >
                <span>
                  <FormattedMessage id="Upload" />
                </span>
              </ButtonCustom>
            </Upload>
          </ColFormItemComponent>
        </Form>
      </React.Fragment>
    );
  }
);

export default TicketingForm;
