import {
  useMobileContext,
  useThemeContext,
} from 'AppProvider/ConfigProviderSettings';
import { Modal, ModalProps } from 'antd';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { isTablet } from 'react-device-detect';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFormValidation,
  setMethodUpdate,
} from 'redux/slices/methodUpdateSlice';
import { RootState } from 'redux/store';
import './Modal.less';
import { useModalContext } from './ModalProvider';

type JustifyContentProperty =
  | 'flex-end'
  | 'center'
  | 'flex-start'
  | (string & {});
export interface IModalComponentProps extends ModalProps {
  displayFooter?: boolean;
  footerButtons?: ReactNode;
  editButton?: ReactNode;
  displayHeaderCloseBtn?: boolean;
  displayFooterCloseButton?: boolean;
  displayFooterSubmitButton?: React.ReactNode;
  displayFooterAdditionalButton?: boolean;
  isOpen?: boolean;
  isTopRoleModalOpen?: boolean;
  setIsTopRoleModalOpen?: Dispatch<SetStateAction<boolean>>;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  isCentered?: boolean;
  alignSelf?: 'bottom' | 'middle' | 'top';
  isMaskClosable?: boolean;

  submitBtnText?: string | JSX.Element;
  cancelBtnText?: string | JSX.Element;
  additionalBtnText?: string;
  onSubmit?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void | boolean;
  formKeyId?: string;
  responseStatus?: string;
  disableSubmitButton?: boolean;

  justifyTitleContent?: JustifyContentProperty;
  justifyRequestButtonsStyle?: JustifyContentProperty;

  className?: string;
  modalKey?: string;

  onCancelPressed?: any;
  setIsEdit?: any;
  afterClose?: any;
}

const ModalComponent: FC<IModalComponentProps> = ({
  formKeyId,
  title,
  width,
  children,
  footerButtons,
  editButton,
  displayFooter,
  submitBtnText,
  additionalBtnText,
  cancelBtnText,
  displayHeaderCloseBtn,
  displayFooterCloseButton,
  displayFooterAdditionalButton,
  displayFooterSubmitButton,
  isOpen,
  setIsOpen,
  isCentered,
  alignSelf = 'top',
  isMaskClosable,
  onSubmit,
  disableSubmitButton,
  justifyTitleContent,
  justifyRequestButtonsStyle,
  className,
  maskStyle,
  modalKey,
  onCancelPressed,
  setIsEdit,
  afterClose,
}) => {
  const dispatch = useDispatch();
  const {
    isModalOpen,
    toggleModal,
    isTopRoleModalOpen,
    setIsTopRoleModalOpen,
    toggleTopRoleModal,
  } = useModalContext();
  const { theme } = useThemeContext();
  const stateSelector = useSelector((state: RootState) => state);
  const methodUpdateSelector = stateSelector.methodUpdate;
  const [isSaveButtonSubmited, setIsSaveButtonSubmited] =
    useState<boolean>(false);
  useEffect(() => {
    if (methodUpdateSelector.isMethodUpdated) {
      dispatch(setMethodUpdate(false));
    }

    if (methodUpdateSelector.isFormValid) {
      dispatch(setFormValidation(false));
    }
  }, []);

  const ButtonCustom = BaseButton(ButtonSimple);
  const { isMobile, deviceOS, isTabletDevice } = useMobileContext();
  const handleOnCancel = () => {
    setIsEdit && setIsEdit(false);
    setIsOpen ? setIsOpen(false) : toggleModal();
    setIsTopRoleModalOpen ? setIsTopRoleModalOpen(false) : toggleTopRoleModal();
  };

  const intl = useIntl();

  const sendUnfinishedText = intl.formatMessage({ id: 'sendUnfinished' });

  return (
    <>
      <Modal
        key={modalKey}
        title={
          <div
            className={`${theme}`}
            style={
              !isMobile
                ? {
                    display: 'flex',
                    justifyContent: justifyTitleContent ?? 'center',
                  }
                : {}
            }
          >
            {title}
          </div>
        }
        maskStyle={maskStyle ?? { opacity: 0.5 }}
        className={`modal-component ${className ?? ''} ${alignSelf} ${theme} ${
          deviceOS === 'IOS' ? 'modal-ios' : ''
        } modal_requests`}
        okText="submit"
        open={isOpen ?? isModalOpen ?? isTopRoleModalOpen}
        onCancel={handleOnCancel}
        destroyOnClose
        width={
          width ||
          (isMobile && '100%') ||
          ((isTablet || isTabletDevice) && 'auto') ||
          'auto'
        }
        centered={isCentered ?? true}
        maskClosable={isMaskClosable ?? true}
        // maskClosable={true}
        closable={displayHeaderCloseBtn ?? false}
        getContainer={() => document.getElementById('mainSection')}
        footer={
          displayFooter ?? true
            ? [
                <div
                  key="modalFooterButtons"
                  className="modal-footer-buttons"
                  style={{ justifyContent: justifyRequestButtonsStyle }}
                >
                  {footerButtons}

                  {displayFooterCloseButton ?? true ? (
                    <ButtonCustom
                      className={`text-bold-normal btn-default-custom  ${theme}`}
                      key="closeModalButton"
                      style={{ maxWidth: '100%' }}
                      onClick={() => {
                        dispatch(setMethodUpdate(false));
                        dispatch(setFormValidation(false));
                        setIsEdit && setIsEdit(false);
                        setIsOpen ? setIsOpen(false) : toggleModal();
                        onCancelPressed && onCancelPressed();
                      }}
                    >
                      {cancelBtnText ?? <FormattedMessage id="cancel" />}
                    </ButtonCustom>
                  ) : null}
                  {editButton}
                  {displayFooterAdditionalButton && !isMobile && (
                    <ButtonCustom
                      className={`text-bold-normal btn-primary-custom ${theme}`}
                      type="primary"
                      form={formKeyId}
                      key="submitAdditionalButton"
                      htmlType="submit"
                      id="additionalButton"
                      disabled={isSaveButtonSubmited}
                      onClick={
                        onSubmit ??
                        ((e) => {
                          setIsSaveButtonSubmited(true);
                          e.currentTarget.click();
                          setTimeout(() => {
                            setIsSaveButtonSubmited(false);
                          }, 2500);
                        })
                      }
                    >
                      {additionalBtnText ?? sendUnfinishedText}
                    </ButtonCustom>
                  )}
                  {displayFooterSubmitButton ?? true ? (
                    <ButtonCustom
                      className={`text-bold-normal btn-primary-custom ${theme}`}
                      type="primary"
                      form={formKeyId}
                      key="submitModalButton"
                      htmlType="submit"
                      id="submitButton"
                      disabled={isSaveButtonSubmited}
                      onClick={
                        onSubmit ??
                        ((e) => {
                          setIsSaveButtonSubmited(true);
                          e.currentTarget.click();
                          setTimeout(() => {
                            setIsSaveButtonSubmited(false);
                          }, 2500);
                        })
                      }
                    >
                      {submitBtnText ?? <FormattedMessage id="submit" />}
                    </ButtonCustom>
                  ) : null}
                  {displayFooterAdditionalButton && isMobile && (
                    <>
                      <div className="additional-btn-wrapper">
                        <ButtonCustom
                          className={`text-bold-normal btn-primary-custom ${theme}`}
                          type="primary"
                          form={formKeyId}
                          key="submitAdditionalButton"
                          htmlType="submit"
                          id="additionalButton"
                          disabled={isSaveButtonSubmited}
                          onClick={
                            onSubmit ??
                            ((e) => {
                              setIsSaveButtonSubmited(true);
                              e.currentTarget.click();
                              setTimeout(() => {
                                setIsSaveButtonSubmited(false);
                              }, 2500);
                            })
                          }
                        >
                          {additionalBtnText ?? sendUnfinishedText}
                        </ButtonCustom>
                      </div>
                    </>
                  )}
                </div>,
              ]
            : null
        }
        afterClose={afterClose}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalComponent;
