import {
  useMobileContext,
  useThemeContext
} from 'AppProvider/ConfigProviderSettings';
import { ArrowUnion } from 'Icons/General/arrowUnion';
import { RemoveSvg } from 'Icons/Teams/remove';
import { Select } from 'antd';
import { SelectDropdown } from 'components/Dropdown';
import ModalComponent from 'components/Modal';
import useApiRequestHook from 'hooks/useApiRequest.hook';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setEmployee } from 'redux/slices/employeeSlice';
import { setMethodUpdate } from 'redux/slices/methodUpdateSlice';
import { setTeams } from 'redux/slices/teamSlice';
import { RootState } from 'redux/store';
import { EmployeeServices, TeamsService } from 'services';
import { IEmployee, ITeam } from 'services/providers/EmployeeProvider/types';
import { getSuccessMessages } from 'utils/getSuccessMessages';
import { firstCharToUpperCase } from 'utils/lang/utils';
import { notifyWithSuccessfulDataUpdated } from 'utils/notificationsUtils';
import './teamsStyles.less';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';

const { Option } = Select;

export const ModalViewEmployees = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { members, isModalOpen, setIsModalOpen, teamId, setIsAddMemberModal } = props;

  const [employeeIds, setEmployeeIds] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [popupOpen, setPopupOpen] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const { theme } = useThemeContext();

  const { data } = useApiRequestHook<IEmployee[]>(
    EmployeeServices.getAll,
    'false',
    []
  );

  const teams = useApiRequestHook<ITeam[]>(
    TeamsService.getAllTeams,
    'true',
    [],
    {}
  );

  const methodUpdateSelector = useSelector(
    (state: RootState) => state.methodUpdate
  );

  const employeeWithoutMembers = data.filter(employee => {
    const currentDate = new Date();
    const isMember =
      members &&
      members.some(member => member.employeeId === employee.employeeId);
    const isActive =
      !employee.endDate || new Date(employee.endDate) > currentDate;

    return !isMember && isActive;
  });

  const handleChange = (event: number[]) => {
    setSelectedValues(event);
    const selected = data.filter(
      employee =>
        event.includes(employee.employeeId) &&
        !selectedEmployees.some(
          selectedEmployee =>
            selectedEmployee.employeeId === employee.employeeId
        )
    );
    setSelectedEmployees([...selectedEmployees, ...selected]);
  };

  const handleRemoveSelectedEmployee = employeeId => {
    const updatedSelectedEmployees = selectedEmployees.filter(
      employee => employee.employeeId !== employeeId
    );
    const updatedSelectedOptions = selectedValues.filter(
      id => id !== employeeId
    );
    setSelectedEmployees(updatedSelectedEmployees);
    setSelectedValues(updatedSelectedOptions);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    const payload = {
      teamId: teamId,
      employeeIds: selectedEmployees.map(employee => employee.employeeId)
    };
    TeamsService.assignMember(payload).then(() => {
      dispatch(setMethodUpdate(true));
      notifyWithSuccessfulDataUpdated(
        '',
        getSuccessMessages.success_member_added
      );
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedEmployees([]);
  };

  useEffect(() => {
    if (methodUpdateSelector.isMethodUpdated === false) {
      dispatch(setTeams(teams.data));
      dispatch(setEmployee(data));
    }
    setEmployeeIds([]);
  }, [teams.data, methodUpdateSelector.isMethodUpdated]);

  const addMemberModalTitle = firstCharToUpperCase(
    intl.formatMessage({ id: 'AddMember' })
  );

  const ITEM_HEIGHT = 37;
  const MAX_ITEMS = 4;

  const listHeight =
    employeeWithoutMembers?.length > MAX_ITEMS
      ? ITEM_HEIGHT * MAX_ITEMS
      : ITEM_HEIGHT * employeeWithoutMembers.length;

  return (
    <>
      <ModalComponent
        className="modal-component-employee--add"
        title={addMemberModalTitle}
        open={isModalOpen}
        onSubmit={handleOk}
        onCancel={handleCancel}
        submitBtnText={<FormattedMessage id="add" />}
        cancelBtnText={<FormattedMessage id="cancel" />}
        width="600px"
        isMaskClosable={false}
        alignSelf="middle"
        afterClose={() => {
          setSelectedEmployees([]);
          setSelectedValues([]);
          setIsAddMemberModal(false)
        }}
        children={
          <div className="teamAddMember">
            <div className="members">
              {selectedEmployees.map(employee => (
                <div className="member" key={employee.employeeId}>
                  <span className="member-name">
                    {employee.lastName} {employee.firstName}
                  </span>
                  <span
                    className="member-icon-remove"
                    onClick={() =>
                      handleRemoveSelectedEmployee(employee.employeeId)
                    }
                  >
                    <RemoveSvg />
                  </span>
                </div>
              ))}
            </div>
            <div
              className="member-select"
              style={{
                ...{
                  minHeight: isDropdownOpened
                    ? `${listHeight + ITEM_HEIGHT}px`
                    : `0px`
                }
              }}
            >
              <ColFormItemComponent
                span={24}
                name="members"
                className={`col-form-item-component members_select ${theme}`}
              >
                <SelectDropdown

                  listHeight={listHeight}
                  placement_bottom={true}
                  placeholder={intl.formatMessage({
                    id: 'teamsSelectPlaceholder_select_member'
                  })}
                  mode={'multiple'}
                  maxTagPlaceholder={intl.formatMessage({
                    id: 'teamsSelectPlaceholder_select_member'
                  })}
                  onChange={handleChange}
                  onDeselect={handleRemoveSelectedEmployee}
                  maxTagCount={0}
                  allowClear={false}
                  suffixIcon={<ArrowUnion />}
                  onDropdownVisibleChange={() =>
                    setTimeout(
                      () => setIsDropdownOpened(!isDropdownOpened),
                      300
                    )
                  }
                  // open={true}
                  open={isDropdownOpened}
                  // onFocus={() => setTimeout(() => setIsDropdownOpened(!isDropdownOpened), 300)}
                  // onBlur={() => setIsDropdownOpened(false)}
                  value={selectedValues}
                >
                  {employeeWithoutMembers.map(employee => (
                    <Option
                      key={employee.employeeId}
                      value={employee.employeeId}
                    >
                      {`${employee.lastName} ${employee.firstName}, ${employee.position}`}
                    </Option>
                  ))}
                </SelectDropdown>
              </ColFormItemComponent>
            </div>
          </div>
        }
      />
    </>
  );
};
